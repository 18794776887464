import { Grid, Hidden, makeStyles } from "@material-ui/core";
import SidebarImage from "../../public/assets/sidebar.png";

const useStyles = makeStyles({
  container: {
    height: "100%",
  },
  leftSidebar: {
    backgroundImage: `url(${SidebarImage})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
  rightSidebar: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 16,
  },
});

const AuthLayout = ({ children }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.container} container spacing={0}>
      <Hidden xsDown>
        <Grid item className={classes.leftSidebar} sm={6} md={8} />
      </Hidden>
      <Grid className={classes.rightSidebar} item xs={12} sm={6} md={4}>
        {children}
      </Grid>
    </Grid>
  );
};

export default AuthLayout;
