import { useMemo } from "react";
import FacebookLogin from "react-facebook-login";
import { useTranslation } from "i18n";
import { useAppState } from "store";
import Logo from "components/Logo";
import Link from "next/link";
import { Box, Button, makeStyles, TextField, Typography } from "@material-ui/core";
import { Facebook } from "@material-ui/icons";
import { sessions, users } from "api";
import * as yup from "yup";
import { useFormik } from "formik";
import { withNoAuthentication } from "hocs";
import withAuthLayout from "hocs/withAuthLayout";
import { getBrowserLanguageNumber } from "utils";

const useStyles = makeStyles((theme) => ({
  logoRightSidebar: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  form: {
    maxWidth: 300,
    display: "flex",
    flexDirection: "column",
    "& > *": {
      marginTop: theme.spacing(3),
    },
  },
  facebookButtonWrapper: {
    border: "none",
    width: "100%",
    background: "transparent",
  },
}));

const SignIn = () => {
  const { setState } = useAppState();
  const { t } = useTranslation();
  const classes = useStyles();
  const validationSchema = useMemo(() => yup.object({
    email: yup
      .string(t("ENTER_EMAIL"))
      .email(t("ENTER_VALID_EMAIL"))
      .required(t("EMAIL_REQUIRED")),
    password: yup
      .string(t("ENTER_PASSWORD"))
      .min(8, t("PASSWORD_MUST_BE_LONG"))
      .required(t("PASSWORD_REQUIRED")),
  }), []);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: async ({ email, password } = {}, { setErrors }) => {
      const { data, error } = await sessions.create({
        platformId: 0,
        method: "local",
        email,
        password,
      });
      if (error) {
        setErrors({ response: t(error) });
        return;
      }
      const { accessToken } = data;
      localStorage.setItem("accessToken", accessToken);
      setState((s) => ({ ...s, loading: true }));
    },
  });

  const responseFacebook = async (response) => {
    formik.setSubmitting(true);
    const authentication = {
      platformId: 0,
      languageId: getBrowserLanguageNumber(),
      method: "facebook",
      facebookToken: response.accessToken,
    };
    const { data: creationData, error: creationError } = await users.create(authentication);
    if (creationError) {
      const { data, error } = await sessions.create(authentication);
      formik.setSubmitting(false);
      if (error) {
        formik.setErrors({ response: t(error) });
        return;
      }
      localStorage.setItem("accessToken", data.accessToken);
      setState((s) => ({ ...s, loading: true }));
      return;
    }
    localStorage.setItem("accessToken", creationData.accessToken);
    setState((s) => ({ ...s, loading: true }));
  };

  return (
    <Box>
      <Box className={classes.logoRightSidebar}>
        <Logo width={150} complete />
        <Typography variant="h6" className="bold">{t("CATCHWORD")}</Typography>
      </Box>
      <form
        className={classes.form}
        onSubmit={formik.handleSubmit}
      >
        <TextField
          id="email"
          label="Email"
          name="email"
          onChange={formik.handleChange}
          error={
            (formik.touched.email && !!formik.errors.email)
              || formik.errors.response
          }
          helperText={formik.touched.email && formik.errors.email}
        />
        <TextField
          label={t("PASSWORD")}
          name="password"
          type="password"
          id="password"
          onChange={formik.handleChange}
          error={
            (formik.touched.password && !!formik.errors.password)
                 || formik.errors.response
          }
          helperText={formik.touched.password && formik.errors.password}
        />
        {
          formik.errors.response
                && (
                  <Typography variant="body2" color="error">
                    {formik.errors.response}
                  </Typography>
                )
        }
        <Button
          fullWidth
          disabled={formik.isSubmitting}
          variant="contained"
          color="primary"
          type="submit"
        >
          {t("LOGIN")}
        </Button>
        <FacebookLogin
          appId={process.env.NEXT_PUBLIC_FB_ID}
          autoLoad={false}
          fields="name,email,picture"
          callback={responseFacebook}
          cssClass={classes.facebookButtonWrapper}
          disableMobileRedirect
          textButton={(
            <Button
              fullWidth
              disabled={formik.isSubmitting}
              variant="outlined"
              color="primary"
              startIcon={<Facebook />}
            >
              {t("FACEBOOK")}
            </Button>
          )}
        />
        <Link href="/signup">
          <Button variant="text">
            {t("NOTACCOUNT")}
          </Button>
        </Link>
        <Link href="/recovery">
          <Button variant="text">
            {t("FORGOT_PASSWORD")}
          </Button>
        </Link>
      </form>
    </Box>
  );
};

export default withAuthLayout(withNoAuthentication(SignIn));
