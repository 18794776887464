import AuthLayout from "layouts/AuthLayout";

/* eslint-disable no-param-reassign */
const withAuthLayout = (Screen) => {
  Screen.getLayout = (page) => (
    <AuthLayout>
      {page}
    </AuthLayout>
  );
  return Screen;
};

export default withAuthLayout;
